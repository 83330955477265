import service from "@/utils/request";

/**
 * 添加错题集
 * @param paper
 * @returns {*}
 */
export function addWrong(wrong) {
    return service(
        {
            url: 'wrong/addWrong',
            method: 'post',
            header: {
                "Content-Type": "application/json" //如果写成contentType会报错
            },
            data: wrong
        })
}

/**
 * 查找所有的wrong信息
 * @param userId
 * @returns {AxiosPromise}
 */
export function getWrong(userId) {
    return service(
        {
            url: 'wrong/getWrong',
            method: 'post',
            params:{
                userId
            }
        })
}

/**
 * 更新错题集
 * @param wrong
 * @returns {*}
 */
export function updateWrong(wrong) {
    return service(
        {
            url: 'wrong/updateWrong',
            method: 'post',
            header: {
                "Content-Type": "application/json" //如果写成contentType会报错
            },
            data: wrong
        })
}

/**
 * 更新
 * @param id
 * @returns {AxiosPromise}
 */
export function deleteWrong(id) {
    return service(
        {
            url: 'wrong/deleteWrong',
            method: 'post',
            params:{
                id
            }
        })
}


