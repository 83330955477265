<template>
  <div class="error">
    <el-row class="title">
      你一共收藏了{{this.wrong.length}}个错题
    </el-row>
    <el-tabs type="border-card">
      <el-tab-pane v-if="choose">
        <span slot="label"><i class="fa fa-wrench"></i>选择题{{choose.length}}道</span>
        <Choose :one="item"  v-for="(item,index) in choose" :key="index"></Choose>
      </el-tab-pane>
      <el-tab-pane v-if="judge">
        <span slot="label"><i class="fa fa-adjust"></i>判断题{{judge.length}} 道</span>
        <Choose :one="item" v-for="(item,index) in judge" :key="index"></Choose>
      </el-tab-pane>
      <el-tab-pane v-if="completion">
        <span slot="label"><i class="fa fa-plug"></i>填空题{{completion.length}} 道</span>
        <Choose :one="item"  v-for="(item,index) in completion" :key="index"></Choose>
      </el-tab-pane>
      <el-tab-pane v-if="shortAnswer">
        <span slot="label"><i class="fa fa-hashtag"></i>简答题{{shortAnswer.length}} 道</span>
        <Choose :one="item"  v-for="(item,index) in shortAnswer" :key="index"></Choose>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {getWrong} from "@/api/wrong";
import Choose from "@/views/paper/common/show-paper";


//错题集
export default {
  name: "error",
  data(){
    return{
      wrong:[],
      // 选择题
      choose:[],
      // 填空题
      completion:[],
      // 判断题
      judge:[],
      // 简答题
      shortAnswer:[]
    }
  },
  components:{
    Choose,
  },
  mounted() {
    // 获取错题集
    this.get();
  },
  methods:{
    get(){
      getWrong(this.$store.state.user.id).then(result=>{
        this.wrong=result.data.wrong;
        if(this.wrong!==null){
          this.wrong.forEach(item=>{
            if(item.paperType===1){
              this.choose.push(item)
            }else if(item.paperType===3){
              this.completion.push(item)
            }else if(item.paperType===2){
              this.judge.push(item)
            }else if(item.paperType===4){
              this.shortAnswer.push(item)
            }
          })
        }
      }).catch(error=>{
        this.$message.error(error.message)
      })
    }
  }
}
</script>

<style scoped>
.error{
  background-color: #fff;
  padding: 20px;
}
/* 标签内容*/
.title{
  text-align: left;
  margin-bottom: 20px;
  line-height: 40px;
  height: 40px;
  font-size: 20px;
  padding: 6px;
}


</style>
