<template>
  <div class="choose">
    <el-row class="title">
      <div>题目:{{ one.paperTopic }}</div>
    </el-row>
    <el-row>
      <el-row class="group-row" v-if="one.paperType===1">
        <el-radio-group v-model="radio" class="group">
          <el-radio :label="1" class="radio">
            <span v-html="one.paperOne"></span>
          </el-radio>
          <el-radio :label="2" class="radio">
            <span class="demo" v-html="one.paperTwo"></span>
          </el-radio>
          <el-radio :label="3" class="radio">
            <span v-html="one.paperThree"></span>
          </el-radio>
          <el-radio :label="4" class="radio">
            <span v-html="one.paperFour"></span>
          </el-radio>
        </el-radio-group>
      </el-row>
      <el-row class="group-row" v-else-if="one.paperType===2">
        <el-radio-group v-model="radio" class="group">
          <el-radio :label="1" class="radio">
            对
          </el-radio>
          <el-radio :label="2" class="radio">
            错
          </el-radio>
        </el-radio-group>
      </el-row>
      <el-row class="group-row" v-else-if="one.paperType===3">
        <el-input v-model="input" placeholder="请输入填空题答案"></el-input>
      </el-row>
      <el-row class="shortAnswer" v-else-if="one.paperType===4">
        <el-input type="textarea"
                  autosize
                  placeholder="请输入简答题答案内容"
                  v-model="input"></el-input>
      </el-row>
      <el-row>
        <el-descriptions>
          <el-descriptions-item label="难度">
            <el-rate
                v-model="one.paperRank"
                disabled
                show-score
                text-color="#ff9900">
            </el-rate>
          </el-descriptions-item>
        </el-descriptions>
      </el-row>
    </el-row>
    <el-row type="flex" class="show-answer">
      <el-button  @click="flag=!flag">查看答案</el-button>
    </el-row>
    <el-row v-if="flag" class="answer">
      答案:
      <div v-html="one.paperAnswer"></div>
      解释:
      <div v-html="one.paperExplain"></div>
    </el-row>
  </div>
</template>
<script>

// 展示简答题
export default {
  name: "show-paper",
  components: {
    // littleTag
  },
  props: ['one'],
  mounted() {
    console.log(this.one)
  },
  data() {
    return {
      input: "",
      disabled: false,
      flag: false,
      radio: 1,
      iconClasses: ['icon-rate-face-1', 'icon-rate-face-2', 'icon-rate-face-3']
    }
  },
  watch: {
    one() {
      console.log("监听到show-paper变化")
      this.flag = false;
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.choose {
  margin-bottom: 20px;
  min-height: 250px;
  width: 100%;
  padding: 15px;
}

/* 标签css*/

/* 标题*/
.title {
  text-align: left;
  /*height: 40px;*/
  width: 100%;
  height: auto;
  display: block;
  padding: 6px;
  font-size: 20px;
  margin: 10px auto;
}

.group-row {
  margin-bottom: 20px;
}

/* 中间内容*/
.radio {
  display: flex;
  text-align: left;
  font-size: 20px;
  padding: 10px;
}

.demo {

}

.group {
  width: 100%;
  /*float: left;*/
}

.group span {
  font-size: 17px;
}


.shortAnswer {
  margin-bottom: 20px;
  min-height: 100px;
  /*width: 80%;*/
}

/* 答案区*/
.answer {
  text-align: left;
  margin: 10px auto;
}

.answer div {
  font-size: 20px;
  padding: 6px;
}

/*右边*/
.show-answer {
  margin: 10px auto;
}

.right {
  padding: 10px;
}

</style>
